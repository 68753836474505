// import Vue from 'vue';
import Vuex from 'vuex';

// Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showFooterMenu: true,
  },
  mutations: {
    setShowFooterMenu(state, value) {
      state.showFooterMenu = value;
    },
  },
  actions: {
    updateShowFooterMenu({ commit }, value) {
      commit('setShowFooterMenu', value);
    },
  },
  getters: {
    getShowFooterMenu: (state) => state.showFooterMenu,
  },
});
