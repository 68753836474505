const { mapState } = require('vuex')
module.exports = {
    computed: mapState({
        count: state => state.count,

        countAlias: 'count',

        countPlusLocalState (state) {
            return state.count + this.localCount
        }
    }),
    // baseUrl: 'http://localhost:8088/',
    // baseUrl: 'https://hca.vestatest.ir/',
    baseUrl: 'https://test.vestatest.ir/',

    serverBaseUrl: 'https://hca.vestatest.ir'
  }
  // store.js

