import {createRouter,createWebHashHistory} from "vue-router";

const routes=[
    { path:"/register",
        name:"Register_page" ,
        component: () => import('@/components/auth/Register'),
    },
    { path:"/Login",
        name:"Login_page" ,
        component: () => import('@/components/auth/Login'),
    },
    { path:"/",
        name:"DashboardPage" ,
        component: () => import('@/layouts/Dashboard'),
        children:[
            { path:"/bill_of_lading",
                name:"BillOfLading" ,
                component: () => import('@/views/barname/BillOfLading'),
            },
            { path:"/profile",
                name:"ProfilePage" ,
                component: () => import('@/views/profile/ProfilePage'),
            },
            { path:"/",
                name:"HomePage" ,
                component: () => import('@/views/home/HomePage'),
            },
            { path:"/trucks",
                name:"TruckPage" ,
                component: () => import('@/views/trucks/TruckPage'), 
            },
            { path:"/drivers_fees",
                name:"DriversFees" ,
                component: () => import('@/views/costs/DriversFees'),
            },
            { path:"/cost_type",
                name:"CostType" ,
                component: () => import('@/views/costs/CostType'),
            },
            { path:"/insert_cost/:id/:type",
                name:"InsertCost" ,
                component: () => import('@/views/costs/InsertCost'),
            },
            { path:"/report_management",
                name:"ReportsManagement" ,
                component: () => import('@/views/report/ReportsManagement'),
            },
            { path:"/edit_barname/:id",
                name:"EditBillOfLading" ,
                component: () => import('@/views/barname/EditBillOfLading'),
            },
        ]
    },
]

const router=createRouter({
    history:createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})

export default router;