<template>
  <router-view></router-view>
  <notifications/>
</template>

<script>
import 'boxicons/css/boxicons.min.css';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'App',
  mounted() {
    this.disableZoom();
  },
  methods: {
    disableZoom() {
      document.documentElement.style.touchAction = 'manipulation';
    },
    enableZoom() {
      document.documentElement.style.touchAction = '';
    },
  },
  beforeUnmount() {
    this.enableZoom();
  },
};
</script>

<style>
</style>
