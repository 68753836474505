import { createApp } from 'vue'
import App from './App.vue'

//store(vuex)
import state from './store/state'
import store from './store/store'

import axios from 'axios'
axios.defaults.baseURL = state.serverBaseUrl + '/api'
axios.defaults.headers.common['Content-Type'] = 'Application/json'
axios.defaults.headers.common.Accept = 'Application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

import router from './router.js'

import './assets/sass/index.sass'

import './style.css'

import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'

import Notifications from '@kyvg/vue3-notification'

import 'boxicons/css/boxicons.css'

import vSelect from 'vue-select'

import { createI18n } from 'vue-i18n'
import fa from './i18n/fa'
import en from './i18n/en'
import './registerServiceWorker'
import AddToHomescreen from '@owliehq/vue-addtohomescreen';

const i18n = createI18n({
    locale: 'fa', // set the default locale
    messages: {
        fa,
        en
    }
})


router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (!token) {
        if(to.path === '/Login' || to.path === '/register'){
            next()
        }
        else{
            next('/Login');
        }
    }
    else if(token && to.path === '/Login' || to.path === '/register'){
        next(from);
    }
    else if(token && to.path !== '/Login' && to.path !== '/register'){
        next();
    }
});

const app= createApp(App)
app.component('DatePicker', Vue3PersianDatetimePicker)
app.use (router)
app.use(i18n)
app.use(store)
app.use(Notifications)
app.component('v-select', vSelect)
app.use(AddToHomescreen, {
    buttonColor: 'blue',
  });
app.mount('#app')

